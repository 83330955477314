import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Listings from '@components/listings';

export default function ForSale({ data, pageContext, location }) {
  return (
    <Listings
      location={location}
      page="/for-sale"
      slug={pageContext.slug ? pageContext.slug.replace(/\//g, ``) : ``}
      listings={[
        ...data.forSale.edges,
        ...data.equipment.edges,
        ...data.attachments.edges,
      ]}
      counts={{
        equipment: data.equipmentCount.totalCount,
        attachments: data.attachmentsCount.totalCount,
        forSale:
          data.forSale.totalCount +
          data.equipmentForSaleCount.totalCount +
          data.attachmentsForSaleCount.totalCount,
      }}
    />
  );
}

ForSale.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export const query = graphql`
  query forSale($slug: String!) {
    forSale: allPrismicForSale(
      filter: { data: { category: { uid: { regex: $slug } } } }
      sort: { fields: data___title___text }
    ) {
      totalCount
      edges {
        node {
          id
          uid
          type
          data {
            title {
              text
            }
            image {
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            coming_soon
            selling_price {
              text
            }
            category {
              uid
              document {
                ... on PrismicCategories {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    equipment: allPrismicEquipment(
      filter: {
        data: { for_sale: { eq: "Yes" }, category: { uid: { regex: $slug } } }
      }
    ) {
      edges {
        node {
          id
          uid
          type
          data {
            title {
              text
            }
            copy {
              text
            }
            image {
              alt
              fluid(maxWidth: 200, maxHeight: 200) {
                ...GatsbyPrismicImageFluid
              }
            }
            coming_soon
            selling_price {
              text
            }
            category {
              uid
              document {
                ... on PrismicCategories {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    attachments: allPrismicAttachments(
      filter: {
        data: { for_sale: { eq: "Yes" }, category: { uid: { regex: $slug } } }
      }
    ) {
      edges {
        node {
          id
          uid
          type
          data {
            title {
              text
            }
            image {
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            coming_soon
            category {
              uid
              document {
                ... on PrismicCategories {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    equipmentCount: allPrismicEquipment(
      filter: { data: { category: { uid: { regex: $slug } } } }
    ) {
      totalCount
    }
    attachmentsCount: allPrismicAttachments(
      filter: { data: { category: { uid: { regex: $slug } } } }
    ) {
      totalCount
    }
    equipmentForSaleCount: allPrismicEquipment(
      filter: {
        data: { for_sale: { eq: "Yes" }, category: { uid: { regex: $slug } } }
      }
    ) {
      totalCount
    }
    attachmentsForSaleCount: allPrismicAttachments(
      filter: {
        data: { for_sale: { eq: "Yes" }, category: { uid: { regex: $slug } } }
      }
    ) {
      totalCount
    }
  }
`;
